import NotFoundPage from '@components/NotFoundPage/NotFoundPage';
import { getCommonProps } from '@scripts/server/getCommonProps';
import { ISSRPageProps } from '@scripts/server/types';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { dehydrate } from 'react-query';

function Error({ isNotFound }: { isNotFound: boolean }) {
    return (
        <NotFoundPage
            title={!isNotFound ? 'Что-то пошло не так' : undefined}
            text={!isNotFound ? 'Что-то пошло не так' : undefined}
        />
    );
}

export const getServerSideProps = async (
    context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<ISSRPageProps>> =>
    getCommonProps(context, async (props, queryClient, apiClient) => {
        const isNotFound = context.res.statusCode === 404;

        return {
            props: {
                ...props,
                isNotFound: isNotFound,
                dehydratedState: dehydrate(queryClient),
            },
        };
    });

export default Error;
